import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/home/HomeView.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/organos',
    name: 'organos',
    component: () => import(/* webpackChunkName: "Organos" */ '../views/sensations/Organs.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/sensaciones',
    name: 'sensaciones',
    component: () => import(/* webpackChunkName: "Sensaciones" */ '../views/sensations/Sensations.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/result-sensaciones',
    name: 'result-sensaciones',
    component: () => import(/* webpackChunkName: "ResultSensaciones" */ '../views/sensations/finalResultSensaciones.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  /* {
    path: "*",
    redirect: "/404",
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("../views/error/Error-1.vue"),
  }, */
  /* {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/AboutView.vue')
  } */
]



const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.getUser
  // console.log(isLoggedIn)
  console.log(to)
  if (to.meta.requiresAuth) {
    if (Object.keys(isLoggedIn).length === 0 && (to.path === '/organos' || to.path === '/sensaciones' || to.path === '/result-sensaciones')) {
      next('/')
    } else {
      next()
    }
    // next()
  } else {
    if (Object.keys(isLoggedIn).length === 0 && to.path === '/') {
      next()
    } else {
      next('/organos')
    }
  }
  /* if (to.meta.requiresAuth) {
    console.log(isLoggedIn);
    
  } else {
    next()
  } */
})



export default router
